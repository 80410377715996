import { useState } from 'react';
import { getCookieKey } from '../../../LocalStorage/CookiesStorage'; 
import environment from '../../../Environments/Environment'

const useUpdateExperience = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [statusCode, setStatusCode] = useState(null);

    const updateExperience = async (scene_info) => {
        setLoading(true);
        try {
            const token = 'Token ' + getCookieKey('token');
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: token },
                body: JSON.stringify(scene_info),
            };
            const response = await fetch(`${environment.server_root}/api/avatar/update_experience/`, requestOptions);
            const result = await response.json();
            setStatusCode(response.status);

            if (!response.ok) {
                throw new Error(result.message || 'Error updating experience');
            }

            setData(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return { data, loading, error, statusCode, updateExperience };
};

export default useUpdateExperience;