import Cookies from 'js-cookie';

// Initialize cookie storage
export const cookieStorage = {
    getStorage: function() {
        const storage = Cookies.get('great_storage');
        return storage ? JSON.parse(storage) : {};
    },
    setStorage: function(storage, expires) {
        Cookies.set('great_storage', JSON.stringify(storage), { expires: expires || 1 }); // Default to 7 days if not provided
    }
};

// Function to set a key-value pair with optional expiration
export function setCookieKey(key, value, expires) {
    const storage = cookieStorage.getStorage();
    storage[key] = value;
    cookieStorage.setStorage(storage, expires);
}

// Function to get a value by key
export function getCookieKey(key) {
    const storage = cookieStorage.getStorage();
    return storage[key] !== undefined ? storage[key] : null;
}

// Function to delete a specific key
export function deleteCookieKey(key) {
    const storage = cookieStorage.getStorage();
    delete storage[key];
    cookieStorage.setStorage(storage);
}

// Function to delete all keys
export function deleteAllCookies() {
    Cookies.remove('great_storage');
}
