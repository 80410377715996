import React from 'react';
import { createRoot } from 'react-dom/client'
import './index.css';
import App from './App';
import { hydrate} from "react-dom";
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  createRoot(document.getElementById('root')).render(<App />)
  // render(<App />, rootElement);
}
reportWebVitals();
// ReactDOM.render(<App />, document.getElementById('root'));