import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const SeoTags = (props) => {
  const [preview, setPreview] = useState("https://storage.googleapis.com/grubox-store/gret/avatar/Icons%20(3)/Icons_3.png");

  useEffect(() => {
    if (props.preview) {
      if (preview !== props.preview) {
        setPreview(props.preview);
      }
    }
  }, [props]);

  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <link rel="canonical" href={props.href} />
        <link rel="alternate" href={props.href} hreflang="en" />
        <meta property="type" content="Virtual Reality" />
        <meta name="description" content={props.description} />
        <meta name="keywords" content="3D website,3dwebsite,virtual experience, 3d experience, 3Dwebsite,virtual office,virtual reality, 3d products,buildvr,BUILDVR,create 3d website" />
        <meta property="keywords" content="3d website builder,vr video,3d rendering website,the virtual tour,a virtual world,VR experience" />
        <meta property="keywords" content="3DwebsiteBuilder,3d website platform,vr website,3d in website,add 3d models in website,high qualty 3d models,Website 3d" />

        {/* Facebook Meta Tags */}
        <meta property="og:title" content={props.title} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={props.description} />
        <meta property="og:image" content={preview} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={props.href} />
        <meta property="og:site_name" content="Buildvr" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content={props.title || "Buildvr Virtual Experience"} />
        <meta name="twitter:description" content={props.description || "Buildvr Virtual Experience"} />
        <meta name="twitter:image" content={preview} />
        <meta name="twitter:url" content={props.href} />
        <meta name="twitter:creator" content="Buildvr" />

        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
      </Helmet>
    </div>
  );
};

export default SeoTags;
