import axios from 'axios';
import environment from '../Environments/Environment'
import { getCookieKey } from '../LocalStorage/CookiesStorage';

export const get_new_model = async (category_id, page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  if (page === null) {
    page = 1
  }
  var url = `${environment.server_root}/api/avatar/get_models?category_id=${category_id}&page=${page}`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_new_model_info = async (category_id, page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  if (page === null) {
    page = 1
  }
  var url = ''
  if (category_id !== '') {
    url = `${environment.server_root}/api/avatar/get_models_info?category_id=${category_id}&page=${page}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_models_info?page=${page}`

  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_recent_models = async (page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  if (page === null) {
    page = 1
  }
  var url = `${environment.server_root}/api/avatar/get_recent_models?page=${page}`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_model_link = async (model_id) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };

  var url = `${environment.server_root}/api/avatar/get_models_link?model_id=${model_id}`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_all_experiences = async (page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = ''
  if (page) {
    url = `${environment.server_root}/api/avatar/get_all_experiences/?page=${page}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_all_experiences/`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_all_shared_experiences = async (page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = ''
  if (page) {
    url = `${environment.server_root}/api/avatar/get_all_shared_experiences_info/?page=${page}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_all_shared_experiences_info/`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_all_experiences_info = async (page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = ''
  if (page) {
    url = `${environment.server_root}/api/avatar/get_all_experiences_info/?page=${page}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_all_experiences_info/`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_all_vrwebsites = async (page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = ''
  if (page) {
    url = `${environment.server_root}/api/avatar/get_all_vrwebsites/?page=${page}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_all_vrwebsites/`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_all_shared_vrwebsite_info = async (page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = ''
  if (page) {
    url = `${environment.server_root}/api/avatar/get_all_shared_vrwebsite_info/?page=${page}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_all_shared_vrwebsite_info/`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_all_model_categories = async (group = null) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = ''
  if (group) {
    url = `${environment.server_root}/api/avatar/get_avatar_categories/?group=${group}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_avatar_categories/`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const get_all_model_categories_grouped = async (group = null) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = ''
  if (group) {
    url = `${environment.server_root}/api/avatar/get_avatar_categories_grouped/?group=${group}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_avatar_categories_grouped/`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const get_all_model_categories_id = async (unique_id = null) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = ''
  if (unique_id) {
    url = `${environment.server_root}/api/avatar/get_avatar_categories_id/?unique_id=${unique_id}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_avatar_categories_id/`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const generate_image = async (prompt) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token }
  }
  var url = `${environment.server_root}/api/avatar/generate_image_chatgpt/?prompt=${prompt}`
  const response = await fetch(url, requestOptions)
  const data = await response.json();
  data.status_code = response.status
  return data
}

export const generate_code = async (prompt, type = null) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token }
  }
  if (type) {
    var url = `${environment.server_root}/api/avatar/prompt_chatgpt35/?prompt=${prompt}&prompt_type=code`
  }
  else {
    var url = `${environment.server_root}/api/avatar/prompt_chatgpt35/?prompt=${prompt}`
  }
  const response = await fetch(url, requestOptions)
  const data = await response.json();
  data.status_code = response.status
  return data
}

export const create_avatar_category = async (category_name, group) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: JSON.stringify({ "category": category_name, 'group': group })
  }
  var url = `${environment.server_root}/api/avatar/create_new_avatar_category/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const engagement_update = async (experience = null, vrwebsite = null, eng_type, comment = null, rating = 0.00) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: JSON.stringify({ "experience": experience, 'vrwebsite': vrwebsite, 'eng_type': eng_type, 'comment': comment, 'rating': rating })
  }
  var url = `${environment.server_root}/api/challenge/engagement_update/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const get_engagement_data = async (experience = null, vrwebsite = null) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
  }
  if (experience) {
    var url = `${environment.server_root}/api/challenge/get_engagement_data?experience=${experience}`
  }
  else if (vrwebsite) {
    var url = `${environment.server_root}/api/challenge/get_engagement_data?vrwebsite=${vrwebsite}`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const get_fav_exp_vrweb = async () => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
  }
  var url = `${environment.server_root}/api/avatar/get_fav_exp_vrweb/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const update_avatar_category_details = async (category_name, group, unique_id) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: JSON.stringify({ "category": category_name, 'group': group, 'unique_id': unique_id })
  }
  var url = `${environment.server_root}/api/avatar/update_avatar_category_details/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const update_avatar_category_image = async (formData) => {
  const token = 'Token ' + getCookieKey('token')
  const config = {
    headers: {
      'content-type': 'multipart/form-data', Authorization: token
    },
  }
  var url = `${environment.server_root}/api/avatar/update_avatar_category_image/`
  const response = await axios.post(url, formData, config)
  const data = await response.data;
  data.status_code = response.status
  return data;
}

export const set_avatar_category = async (unique_id) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: JSON.stringify({ "unique_id": unique_id })
  };
  const response = await fetch(`${environment.server_root}/api/avatar/set_avatar_category/`, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const create_contactus = async (user_data, vrwebsite) => {
  const token = 'Token ' + getCookieKey('token')
  var post_data = {
    ...user_data, ...{ 'vrwebsite': vrwebsite }
  }
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: JSON.stringify(post_data)
  }
  var url = `${environment.server_root}/api/home/create_contactus/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const get_all_vrwebsites_info = async (page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = ''
  if (page) {
    url = `${environment.server_root}/api/avatar/get_all_vrwebsites_info/?page=${page}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_all_vrwebsites_info/`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_user_fav_experiences = async (page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = ''
  if (page) {
    url = `${environment.server_root}/api/avatar/get_user_fav_experiences/?page=${page}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_user_fav_experiences/`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_uploaded_model = async (page, category_id) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = ''
  if (category_id) {
    if (page) {
      url = `${environment.server_root}/api/avatar/get_models?upload=true&category_id=${category_id}&page=${page}`
    }
    else {
      url = `${environment.server_root}/api/avatar/get_models?upload=true&category_id=${category_id}`
    }
  }
  else {
    if (page) {
      url = `${environment.server_root}/api/avatar/get_models?upload=true&page=${page}`
    }
    else {
      url = `${environment.server_root}/api/avatar/get_models?upload=true`
    }
  }

  // var url = `${environment.server_root}/api/avatar/get_models?upload=true&page=${page}`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_user_videos = async (page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = ''
  if (page) {
    url = `${environment.server_root}/api/avatar/get_user_videos/?page=${page}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_user_videos/?page=0`
  }
  // var url = `${environment.server_root}/api/avatar/get_models?upload=true&page=${page}`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const UploadModels = async (formData, type) => {
  const token = 'Token ' + getCookieKey('token')
  // for (var pair of formData.entries()) {
  //   console.log(pair[0] + ', ' + pair[1]);
  // }
  const config = {
    headers: {
      'content-type': 'multipart/form-data', Authorization: token
    },
  };

  var url = `${environment.server_root}/api/avatar/upload_models/`
  const response = await axios.post(url, formData, config)
  const data = await response.data;
  data.status_code = response.status
  return data;
};

export const ConvertVideoToExp = async (model_id, exp_name = 'My Vr video', description = '', is_adult_content = false) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: JSON.stringify({ "model_id": model_id, "exp_name": exp_name, "description": description, "adult_content": is_adult_content })
  };
  const response = await fetch(`${environment.server_root}/api/avatar/create_vr_video_exp/`, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data
};

export const UploadPreviews = async (formData) => {
  const token = 'Token ' + getCookieKey('token')
  // for (var pair of formData.entries()) {
  //   console.log(pair[0]+ ', ' + pair[1]); 
  // }
  const config = {
    headers: {
      'content-type': 'application/json', Authorization: token
    },
  };

  var url = `${environment.server_root}/api/avatar/upload_preview/`
  const response = await axios.post(url, formData, config)
  const data = await response.data;
  data.status_code = response.status
  return data;
};

export const UploadModelFiles = async (formData) => {
  const token = 'Token ' + getCookieKey('token')
  // for (var pair of formData.entries()) {
  //   console.log(pair[0]+ ', ' + pair[1]); 
  // }
  const config = {
    headers: {
      'content-type': 'application/json', Authorization: token
    },
  };

  var url = `${environment.server_root}/api/avatar/upload_models_file/`
  const response = await axios.post(url, formData, config)
  const data = await response.data;
  data.status_code = response.status
  return data;
};

export const UpdateGlbModel = async (formData) => {
  const token = 'Token ' + getCookieKey('token')
  for (var pair of formData.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data', Authorization: token
    },
  };

  var url = `${environment.server_root}/api/avatar/upload_models_file/`
  const response = await axios.post(url, formData, config)
  const data = await response.data;
  data.status_code = response.status
  return data;
};

export const uploadPreviewExperience = async (formData) => {
  const token = 'Token ' + getCookieKey('token')
  // for (var pair of formData.entries()) {
  //   console.log(pair[0]+ ', ' + pair[1]); 
  // }
  const config = {
    headers: {
      'content-type': 'multipart/form-data', Authorization: token
    },
  };

  var url = `${environment.server_root}/api/avatar/upload_preview_experience/`
  const response = await axios.post(url, formData, config)
  const data = await response.data;
  data.status_code = response.status
  return data;
};

export const uploadPreviewVrwebsite = async (formData) => {
  // console.log(formData);
  const token = 'Token ' + getCookieKey('token')
  // for (var pair of formData.entries()) {
  //   console.log(pair[0] + ', ' + pair[1]);
  // }
  const config = {
    headers: {
      'content-type': 'multipart/form-data', Authorization: token
    },
  };

  var url = `${environment.server_root}/api/avatar/upload_preview_vrwebsite/`
  const response = await axios.post(url, formData, config)
  const data = await response.data;
  data.status_code = response.status
  return data;
};

export const delete_model = async (modelId) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: JSON.stringify({ "model_id": modelId })
  };

  const response = await fetch(`${environment.server_root}/api/avatar/delete_model/`, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data
}

export const get_public_experiences_recent = async (page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };

  var url = `${environment.server_root}/api/avatar/get_public_experiences_recent/?page=${page}`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_public_experiences_popular = async () => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };

  var url = `${environment.server_root}/api/avatar/get_public_experiences_popular/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const update_experience_likes = async (experience_id) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: JSON.stringify({ "experience_id": experience_id })
  };

  var url = `${environment.server_root}/api/avatar/update_experience_likes/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_mesh_textures = async (mesh_id, exp_id) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };

  var url = `${environment.server_root}/api/avatar/get_textures/?mesh_id=${mesh_id}&experience_id=${exp_id}`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_experiences_templates = async (category = null, page, featured = false) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };

  var url = ''
  if (featured) {
    if (category) {
      url = `${environment.server_root}/api/avatar/get_exp_templates_by_category?page=${page}&category=${category}&featured=true`
    }
    else {
      url = `${environment.server_root}/api/avatar/get_exp_templates_by_category?page=${page}&featured=true`
    }
  } else {
    if (category) {
      url = `${environment.server_root}/api/avatar/get_exp_templates_by_category?page=${page}&category=${category}`
    }
    else {
      url = `${environment.server_root}/api/avatar/get_exp_templates_by_category?page=${page}`
    }
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_vrweb_templates_by_industry = async (page, industry, featured = false) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = ''
  if (featured) {
    if (industry) {
      url = `${environment.server_root}/api/avatar/get_vrweb_templates_by_industry?page=${page}&industry=${industry}&featured=true`
    }
    else {
      url = `${environment.server_root}/api/avatar/get_vrweb_templates_by_industry?page=${page}&featured=true`
    }
  } else {
    if (industry) {
      url = `${environment.server_root}/api/avatar/get_vrweb_templates_by_industry?page=${page}&industry=${industry}`
    }
    else {
      url = `${environment.server_root}/api/avatar/get_vrweb_templates_by_industry?page=${page}`
    }
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const copy_experience = async (unique_id) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: JSON.stringify({ "unique_id": unique_id })
  };

  var url = `${environment.server_root}/api/avatar/copy_experience/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const copy_vrwebsite = async (unique_id) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: JSON.stringify({ "unique_id": unique_id })
  };

  var url = `${environment.server_root}/api/avatar/copy_vrwebsite/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};