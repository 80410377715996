import { useState, useCallback, useEffect } from 'react';
import { getCookieKey } from '../../../LocalStorage/CookiesStorage';
import environment from '../../../Environments/Environment';

const useUserExperiences = () => {
    const [data, setData] = useState([]); // Initialize data as an array
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [pagination, setPagination] = useState({ // State for pagination
        next_page: null,
        current_page: 1,
        total_pages: null,
    });

    const fetchExperiences = useCallback(async (pageNum) => {
        setLoading(true);
        const token = 'Token ' + getCookieKey('token');
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Authorization: token },
            params: {}
        };

        let url = `${environment.server_root}/api/avatar/get_all_experiences?page=${pageNum}`;

        try {
            const response = await fetch(url, requestOptions);
            const result = await response.json();
            result.status_code = response.status;
            if (response.ok) {
                // Append new data and update pagination
                setData((prevData) => [...prevData, ...result.experience]);
                const newPageData = {
                    next_page: result.next_page, // Assuming the API returns this
                    current_page: pageNum,       // Current page being fetched
                    total_pages: result.total_pages // Total pages available
                };
                setPagination(newPageData);
            } else {
                setError(result); // Handle error response
            }
        } catch (err) {
            setError(err); // Handle fetch error
        } finally {
            setLoading(false);
        }
    }, []);

    // Fetch templates when the industry or featured state changes
    useEffect(() => {
        // Reset the data and pagination state when industry changes
        setData([]);
        setPagination({ next_page: null, current_page: 1, total_pages: null });
        fetchExperiences(1); // Fetch templates for the first page
    }, [fetchExperiences]);

    const refreshData = useCallback(() => {
        setData([]); // Clear current data
        setPagination({ next_page: null, current_page: 1, total_pages: null }); // Reset pagination
        fetchExperiences(1); // Fetch data from the first page again
    }, [fetchExperiences]);

    const loadMoreExperiences = () => {
        if (pagination.next_page) { // Check if there is a next page
            fetchExperiences(pagination.next_page); // Fetch the next page
        }
    };

    return { data, loading, error, pagination, loadMoreExperiences, refreshData };
};

export default useUserExperiences;