import { useState, useEffect } from 'react';
import { getCookieKey } from '../../../LocalStorage/CookiesStorage'; 
import environment from '../../../Environments/Environment'

const useCategories = (group = null) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      const token = 'Token ' + getCookieKey('token');
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: token },
      };

      let url = `${environment.server_root}/api/avatar/get_avatar_categories/`;
      if (group) {
        url += `?group=${group}`;
      }

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();

        if (response.ok) {
          setCategories(data?.categories_info);
        } else {
          throw new Error(data.detail || 'Error fetching categories');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [group]);

  return { categories, loading, error };
};

export default useCategories;
