import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { PointerLockControls } from 'three/addons/controls/PointerLockControls.js';
import TouchControls from '../TouchControl/TouchControls';
import { MapControls } from 'three/addons/controls/OrbitControls.js';
import { ApplyTexture } from "./LoadTextures";
import { TrackballControls } from 'three/addons/controls/TrackballControls.js';

export function updateMeshProperties(child, mesh_properties, type) {
  var index = getIndexofMesh(child.name, mesh_properties)
  if (index != -1) {
    if (mesh_properties[index].roughness) child.material.roughness = mesh_properties[index].roughness
    if (mesh_properties[index].metalness) child.material.metalness = mesh_properties[index].metalness
    // child.material.color = new THREE.Color("#" + mesh_properties[index].color).getHex();
    if (mesh_properties[index].opacity) child.material.opacity = mesh_properties[index].opacity
    if (mesh_properties[index].transparent) child.material.transparent = mesh_properties[index].transparent
    child.scale.set(mesh_properties[index].scale.x, mesh_properties[index].scale.y, mesh_properties[index].scale.z,)
  }
}

export function updateMeshDescription(child, texture_properties, type) {
  var index = getIndexofMeshName(child.name, texture_properties)
  if (index != -1) {
    child['mesh_description'] = texture_properties[index].mesh_description
    child['display_name'] = texture_properties[index].display_name
  }
}



export function updateTextureProperties(child, texture_properties) {
  var index = getIndexofMesh(child.name, texture_properties)
  if (index != -1) {
    ApplyTexture(child, texture_properties[index].glb_file)
  }
}

export function UpdateBasicMeshProperties(child, type) {
  child['type_object'] = type;
  child.castShadow = true
  child.receiveShadow = true
  child.frustumCulled = true;
}
export function getIndexofMeshName(name, mesh_properties) {
  var index = mesh_properties.findIndex(function (element) {
    return element.mesh_name === name;
  });
  return index
}
export function getIndexofMesh(name, mesh_properties) {
  var index = mesh_properties.findIndex(function (element) {
    return element.name === name;
  });
  return index
}

export function setBackground(scene, link) {
  const loader = new THREE.TextureLoader();
  if (link === '' || link === null || link === undefined) {
    loader.load('https://storage.googleapis.com/grubox-store/data_dump/landscape.jpeg', function (texture) {
      scene.background = texture;
    });
  }
  else {
    loader.load(link.bg_image, function (texture) {
      scene.background = texture;
    });
  }
}

export function setBackgroundWithLink(scene, renderer, link) {
  const loader = new THREE.TextureLoader();
  if (link === '' || link === null || link === undefined) {
    loader.load('https://storage.googleapis.com/grubox-store/data_dump/landscape.jpeg', function (texture) {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.needsUpdate = true;
      texture.flipY = true;
      texture.encoding = THREE.SRGBColorSpace;
      texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
      texture.mapping = THREE.ACESFilmicToneMapping;
      scene.background = texture;
    });
  }
  else {
    loader.load(link, function (texture) {
      // console.log(texture)
      // texture.repeat.set(2,4);
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.needsUpdate = true;
      texture.flipY = true;
      texture.encoding = THREE.SRGBColorSpace;
      texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
      texture.mapping = THREE.ACESFilmicToneMapping;

      scene.background = texture;


    });
  }
}

export function setBackgroundSphere(link, scene, renderer) {
  const loader = new THREE.TextureLoader();
  loader.load(link || 'https://storage.googleapis.com/grubox-store/data_dump/landscape.jpeg', function (texture) {
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.needsUpdate = true;
    texture.flipY = true;
    texture.encoding = THREE.SRGBColorSpace;
    texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
    texture.mapping = THREE.ACESFilmicToneMapping;
    const sphere = new THREE.Mesh(
      new THREE.SphereGeometry(200, 64, 32),
      new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
      })
    )
    scene.add(sphere);
  });
}
export function setControlsOrbit(container, camera, scene) {
  let options = {
    delta: 0.75,           // coefficient of movement
    moveSpeed: 0.05,        // speed of movement
    rotationSpeed: 0.006,  // coefficient of rotation
    maxPitch: 55,          // max camera pitch angle
    hitTest: false,         // stop on hitting objects
    hitTestDistance: 20    // distance to test for hit
  }
  var controls = new TouchControls(container, camera, options)
  controls.setPosition(0, 3, 4)
  controls.addToScene(scene)
  return controls
}

export function setControlsFirstPerson(camera) {
  var controls = new PointerLockControls(camera, document.body );
  controls.lookSpeed = 0.05;
  controls.movementSpeed = 50;
  controls.isActive = true
  return controls
}


export function setControlsOrbitScene(renderer, camera, orbitControls) {
  orbitControls = new OrbitControls(camera, renderer.domElement);
  orbitControls.enableDamping = true
  orbitControls.panSpeed = 0.2;
  orbitControls.dampingFactor = 0.3
  return orbitControls
}

export function setMapControlsScene(renderer, camera) {
  var mapControls = new OrbitControls(camera, renderer.domElement);
  //controls.addEventListener( 'change', render ); // call this only in static scenes (i.e., if there is no animation loop)

  mapControls.enableDamping = false; // an animation loop is required when either damping or auto-rotation are enabled
  mapControls.dampingFactor = 0.05;
  mapControls.enablePan = false;

  // mapControls.screenSpacePanning = false;

  mapControls.minDistance = 1;
  mapControls.maxDistance = 300;
  mapControls.listenToKeyEvents(document.body)
  mapControls.keys = {
    LEFT: "ArrowLeft", //left arrow
    UP: "ArrowUp", // up arrow
    RIGHT: "ArrowRight", // right arrow
    BOTTOM: "ArrowDown" // down arrow
  }
  mapControls.mouseButtons = {
    LEFT: THREE.MOUSE.ROTATE,
    MIDDLE: THREE.MOUSE.DOLLY,
    RIGHT: THREE.MOUSE.PAN
  }
  mapControls.touches = {
    ONE: THREE.TOUCH.ROTATE,
    TWO: THREE.TOUCH.DOLLY_ROTATE
  }
  return mapControls

  // mapControls.maxPolarAngle = Math.PI / 2;
}

export function createControls(renderer, camera, controls) {

  controls = new TrackballControls(camera, renderer.domElement);

  controls.rotateSpeed = 1.0;
  controls.zoomSpeed = 1.2;
  controls.panSpeed = 0.8;

  controls.keys = ['KeyA', 'KeyS', 'KeyD'];

}
export const clearThree = (obj) => {
  while (obj.children.length > 0) {
    clearThree(obj.children[0]);
    obj.remove(obj.children[0]);
  }
  if (obj.geometry) obj.geometry.dispose();

  if (obj.material) {
    //in case of map, bumpMap, normalMap, envMap ...
    Object.keys(obj.material).forEach(prop => {
      if (!obj.material[prop])
        return;
      if (obj.material[prop] !== null && typeof obj.material[prop].dispose === 'function')
        obj.material[prop].dispose();
    })
    // console.log(obj.material)
    // obj.material.dispose();
  }

};

export function get_url_extension(url) {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}
export function getFileType(file) {

  var ext = file.name.toLowerCase().split('.').pop();
  if (ext === "glb" || ext === "gltf" || ext === "fbx" || ext === "" || ext === "zip" || ext === "usdz") {
    return 'model';
  }

  else if (file.type.match('image.*')) {
    return 'image';
  }

  else if (file.type.match('video.*')) {
    return 'video';
  }

  else if (file.type.match('audio.*')) {
    return 'audio';
  }


  // etc...
  return 'other';
}


export const tourConfig = [
  {
    selector: '[data-tut="container"]',
    content: "Congratulations! You have successfully Published 3D Space",
  },
  {
    selector: '[data-tut="rotation"]',
    content: "Use Rotate button to checkout 360 degree view"
  },
  {
    selector: '[data-tut="movement"]',
    content: "Use Navigation buttons to move around",
  },
];

export const tourConfigScene = [
  // {
  // selector: '',
  // content: `Welcome to BuildVr! Create. Explore. Share. 3D Experiences`,
  //     style:{ fontSize:'18px'}
  // },
  {
    selector: '[data-tut="sidebar"]',
    content: `Welcome to BuildVr! Choose 3D Models here`,
    style: { fontSize: '18px' }
  },
  {
    selector: '[data-tut="container"]',
    content: "View and Edit 3D Space here",
    style: { fontSize: '18px' }
  },
  {
    selector: '[data-tut="Publish"]',
    content: "Finally! Click here to Publish your Space to web shareable page",
    style: { fontSize: '18px' }
  },
  {
    selector: '[data-tut="Settings"]',
    content: "Change your settings for the scene",
    style: { fontSize: '18px' }
  },
  {
    selector: '[data-tut="Joystick"]',
    content: "Use this joystick to move around your VR experience",
    style: { fontSize: '18px' }
  },
  {
    selector: '[data-tut="container"]',
    content: "Great! You are ready to create",
    style: { fontSize: '18px' }
  },
];

export function ChangeModelScale(scene, meshType, changeType, scene_info) {
  var new_scale = null
  if (changeType === 'increase') {
    scene.children.map(function (child) {
      if (child.type_object === meshType) {
        child.scale.x = child.scale.x + 0.1
        child.scale.y = child.scale.y + 0.1
        child.scale.z = child.scale.z + 0.1
        new_scale = child.scale
      }
    })
  }
  else if (changeType === 'decrease') {
    scene.children.map(function (child) {
      if (child.type_object === meshType) {
        child.scale.x = child.scale.x - 0.1
        child.scale.y = child.scale.y - 0.1
        child.scale.z = child.scale.z - 0.1
        new_scale = child.scale
      }
    })
  }
  else {
    scene.children.map(function (child) {
      if (child.type_object === meshType) {
        var value = parseFloat(changeType)
        child.scale.set(value, value, value)
        new_scale = child.scale
      }
    })
  }
  if (meshType === "env") {
    scene_info.environment_details.size = new_scale
  }
  return new_scale
}

export function ChangeModelScaleVideo(scene,type, changeValue, axis,scene_info) {
      var value = parseFloat(changeValue)
      var new_scale = null
      scene.children.forEach(function (child) {
          if (child.userData === "video") {
              var oldScale = child.scale
              switch (axis) {
                  case 'X':
                      child.scale.set(value, oldScale.y, oldScale.z)
                      break;
                  case 'Y':
                      child.scale.set(oldScale.x, value, oldScale.z)
                      break;
                  default:
                      console.error('Invalid axis:', axis);
              }
              new_scale = child.scale
              scene_info.video_details.size = child.scale
              scene_info.video_details.scale = child.scale
          }
      })
      return new_scale
}

export function removeMesh(scene, type_object) {
  scene.children.map(function (child) {
    if (child.type_object === type_object) {
      if (child.geometry) {
        child.geometry.dispose()
      }
      if (child.material) {
        if (child.material.length) {
          for (let i = 0; i < child.material.length; ++i) {
            child.material[i].dispose()
          }
        }
        else {
          child.material.dispose()
        }
      }
      scene.remove(child);
    }
  })
}



