import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { get_leaderboard_data } from '../../Servicies/ProfileService'

const Leaderboard = () => {
    const [leaderboardDetails, setLeaderboardDetails] = useState([])

    useEffect(() => {
        get_leaderboard_data().then(result => {
            if(result.success){
                const sortedData = [...result.data].sort((a,b) => b.points - a.points)
                setLeaderboardDetails(sortedData)
            }
        })
    },[])

    return (
        <>
            <div className="w3-display-container" style={{ height: '95vh' }}>
                <video autoPlay loop muted src="https://storage.googleapis.com/grubox-store/gret/avatar/Purple%20Game%20Player%20Process%20Concept%20Map/Purple_Game_Player_Process_Concept_Map.mp4" type="video/mp4" style={{ position: 'fixed', width: '100%', height: '100%', objectFit: 'cover', zIndex: '0' }} />
                <h2 className='w3-display-topmiddle'><b style={{ color: 'white' }}>VR Creators Leaderboard</b></h2>
                <div className="w3-display-right w3-padding" style={{ marginRight: '20px', maxHeight: '95vh', overflow: 'auto' }}>
                    {leaderboardDetails.map((item, index) => {
                        return (
                            <>
                                {index === 0 ?
                                    <div key={index} className='w3-card w3-round-large' style={{ marginTop: '20px', width: '60vh', backgroundColor: '#FAF9F6',height:'15vh' }}>
                                        <div className='w3-row'>
                                            <img className='w3-col l1' src={item.icon} style={{ width: '20vh', height:'15vh',borderRadius:'7px 0px 0px 7px' }}></img>
                                            <div className='w3-col l3' style={{ marginLeft: '10px' }}>
                                                <h3 style={{ textAlign: 'center', fontFamily: "Garamond, serif" }}>{item.name}</h3>
                                                <p><i>Points {item.points}</i></p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div key={index} className='w3-card w3-round-large' style={{ marginTop: '20px',marginLeft:'74px', width: '50vh', backgroundColor: '#FAF9F6' }}>
                                        <div className='w3-row'>
                                            <img className='w3-col l1 w3-round-large' src={item.icon} style={{ width: '20vh', height:'12vh',borderRadius:'7px 0px 0px 7px'  }}></img>
                                            <div className='w3-col l3' style={{ marginLeft: '10px' }}>
                                                <h3 style={{ textAlign: 'center', fontFamily: "Garamond, serif" }}>{item.name}</h3>
                                                <p><i>Points {item.points}</i></p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        )
                    })}
                </div>
                <Link to="/create">
                    <div className="w3-display-bottomleft btn btn-animated" style={{ border: '12px solid silver', background: "linear-gradient(#2596be, #76b5c5)", marginLeft: '30px', display: 'flex', width: '155px', height: '155px', borderRadius: '50%' }}>
                        <h3 style={{ margin: 'auto', color: 'white' }}><b>START</b></h3>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default Leaderboard;