export const dark_green_1= '#003929';
export const dark_green_2= '#007251';
export const green= '#00AB79';
export const light_green_1= '#00E4A2';
export const light_green_2= '#72FFD6';
export const light_green_3= '#43ffbb';
export const off_white_1= '#EEEEF7';
export const off_white_2= '#F5F5F5';
export const dark_blue_1='#3F3D6A';
export const dark_blue_2='#272752';
export const dark_blue_3='#0F133B'
export const dark_blue_4='#ACA9BB'
export const dark_blue_5='#3F60A0'
export const light_purple_1='#D4EDF4';
// export const light_purple_1='#393646';
export const dark_purple_1='#8282b4';
export const dark_gray_1='#3b3b3b';
export const grey='#393646';
export const yellow='#F8B507';
export const dark_purple='#575484';
export const color_1 = '#242424';
export const color_2 = '#345c72';
export const color_3 = '#ffffff';
export const color_4 = '#2A2E30';
export const color_5 = '#16191B';
export const color_6 = "#ff9e7a";


export const new_ui_black = "#0D1116"
export const new_ui_gray = "#161C23"
export const new_ui_border = "#222B37"
export const new_ui_blue = "#1053F3"
