import axios from 'axios';
import environment from '../Environments/Environment'
import { getCookieKey } from '../LocalStorage/CookiesStorage';
import { CLIENT_ID, APP_SECRET } from '../Screens/Payment/Config/Config';

export const get_profile_info = async () => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = `${environment.server_root}/api/profile/get_profile_info/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const update_subscription_details = async (content) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: JSON.stringify(content)
  };
  const response = await fetch(`${environment.server_root}/api/profile/update_subscription_details/`, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data
}

export const cancel_subscription_order = async () => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    // body: JSON.stringify(content)
  };
  const response = await fetch(`${environment.server_root}/api/profile/cancel_paypal_subscription/`, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data
}

export const get_paypal_token = async () => {
  var basicAuth = window.btoa(`${CLIENT_ID}:${APP_SECRET}`)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${basicAuth}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: 'grant_type=client_credentials',
  }
  // const response = await fetch('https://api-m.sandbox.paypal.com/v1/oauth2/token',requestOptions)
  const response = await fetch('https://api-m.paypal.com/v1/oauth2/token', requestOptions)
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const cancel_paypal_subscription = async (subscription_id, accessToken) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },

    body: JSON.stringify({ "reason": "Not satisfied with the service" })
  }
  // const response = await fetch(`https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${subscription_id}/cancel`,requestOptions)
  const response = await fetch(`https://api-m.paypal.com/v1/billing/subscriptions/${subscription_id}/cancel`, requestOptions)
  const data = await response;
  data.status_code = response.status
  return data
}

export const update_profile_details = async (formData) => {
  const token = 'Token ' + getCookieKey('token')
  const config = {
    headers: {
      'content-type': 'multipart/form-data', Authorization: token
    },
  };
  var url = `${environment.server_root}/api/profile/update_profile_details/`
  const response = await axios.post(url, formData, config)
  const data = await response.data;
  data.status_code = response.status
  return data;
};

export const get_profile_info_profile_id = async (id) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: { "profile_id": id }
  };
  var url = `${environment.server_root}/api/profile/get_profile_info_profile_id/?profile_id=${id}`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_profile_stats = async () => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    // params: {"personal":true}
  }
  var url = `${environment.server_root}/api/profile/profile_visits_kpi/?personal=true`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const get_user_client_challenges = async () => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
  }
  var client_id = '3472966280152706115'
  var url = `${environment.server_root}/api/challenge/get_user_client_challenges/?client_id=${client_id}`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const get_leaderboard_data = async () => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
  }
  var url = `${environment.server_root}/api/challenge/get_leaderboard_data/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const get_profile_history = async () => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
  }
  var url = `${environment.server_root}/api/points/get_profile_history/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
}

export const post_user_challenge = async (challenge_id) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: JSON.stringify({ challenge: challenge_id })
  };
  const response = await fetch(`${environment.server_root}/api/challenge/add_xp_points/`, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data
}

export const get_all_experiences_profile_id = async (id, page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: { "id": id }
  };
  // console.log(page)
  var url = ``
  if (page) {
    url = `${environment.server_root}/api/avatar/get_all_experiences_profile_id/?profile_id=${id}&page=${page}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_all_experiences_profile_id/?profile_id=${id}`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_all_vrwebsites_profile_id = async (id, page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: { "id": id }
  };
  var url = ``
  if (page) {
    url = `${environment.server_root}/api/avatar/get_all_vrwebsites_profile_id/?profile_id=${id}&page=${page}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_all_vrwebsites_profile_id/?profile_id=${id}`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_all_fav_experiences_profile_id = async (id, page) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: { "id": id }
  };
  // console.log(page)
  // var url = `${environment.server_root}/api/avatar/get_all_fav_experiences_profile_id/?profile_id=${id}`
  var url = ``
  if (page) {
    url = `${environment.server_root}/api/avatar/get_all_fav_experiences_profile_id/?profile_id=${id}&page=${page}`
  }
  else {
    url = `${environment.server_root}/api/avatar/get_all_fav_experiences_profile_id/?profile_id=${id}`
  }
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_all_profile = async (id) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: { "id": id }
  };
  var url = `${environment.server_root}/api/profile/get_all_profiles/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_chart_data = async (dataset) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = `${environment.server_root}/api/profile/get_chart_data/?last_days=${dataset.last_days}&start_date=${dataset.start_date}&end_date=${dataset.end_date}`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const send_promotional_email = async (content) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: JSON.stringify(content)
  };
  const response = await fetch(`${environment.server_root}/api/avatar/send_promotional_email/`, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data
}

export const get_user_experiences_kpi = async (id) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: { "id": id }
  };
  var url = `${environment.server_root}/api/avatar/get_user_experiences_kpi/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_experiences_kpi_profile_id = async (id) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: { "id": id }
  };
  var url = `${environment.server_root}/api/avatar/get_experiences_kpi_profile_id/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const get_recent_profiles = async () => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };
  var url = `${environment.server_root}/api/profile/get_recent_profiles/`
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};

export const profile_visits_kpi = async (page = null, email = null, start_date = null, end_date = null) => {
  const token = 'Token ' + getCookieKey('token')
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    params: {}
  };

  var url = ''
  if (email) {
    url = `${environment.server_root}/api/profile/profile_visits_kpi/?email=${email}`
  }
  else if (start_date && end_date) {
    url = `${environment.server_root}/api/profile/profile_visits_kpi/?start_date=${start_date}&end_date=${end_date}`
  }
  else {
    url = `${environment.server_root}/api/profile/profile_visits_kpi/`
  }
  {/*
  else if(page)
  {
    url = `${environment.server_root}/api/profile/profile_visits_kpi/?page=${page}`
  }*/}
  const response = await fetch(url, requestOptions);
  const data = await response.json();
  data.status_code = response.status
  return data;
};