import React, { useState, useEffect, lazy } from 'react'
import { get_all_experiences, get_user_fav_experiences, get_all_vrwebsites } from '../../../Servicies/ModelService';
import * as colors from '../../../Styles/Colors';
// import * as Media from '../../../Media/Media';
import './profilepages.css'
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { get_profile_info, update_profile_details, get_all_experiences_profile_id, get_profile_info_profile_id, get_all_fav_experiences_profile_id, get_all_vrwebsites_profile_id } from '../../../Servicies/ProfileService';
// import ThreeDotsLoader from '../../LoaderPages/ThreeDotsLoader';
// import ProfileVrCollectionGrid from '../Components/ProfileVrCollectionGrid';
// import ProfileStats from '../Components/ProfileDashboard/ProfileStats';
import { TbEdit } from 'react-icons/tb'
import importRetry from '../../../importRetry';
import PointsTable from '../Components/ProfileDashboard/PointsTable';
import PointsHistoryTable from '../Components/ProfileDashboard/PointsHistoryTable';

const ThreeDotsLoader = lazy(() => importRetry(() => import('../../LoaderPages/ThreeDotsLoader')));
const ProfileVrCollectionGrid = lazy(() => importRetry(() => import('../Components/ProfileVrCollectionGrid')));
const ProfileStats = lazy(() => importRetry(() => import('../Components/ProfileDashboard/ProfileStats')));

const ChallengeProfile = (props) => {
  Modal.setAppElement('#root');

  const { profile_id } = useParams();
  const [profileDetails, setProfileDetails] = useState()
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [editProfileName, setEditProfileName] = useState(false)
  const [editProfileImage, setEditProfileImage] = useState(false)
  const [newProfileInfo, setNewProfileInfo] = useState({ name: null })
  const [imageUpload, setImageUpload] = useState(null);
  const [visible, setVisible] = useState(props.visible);

  useEffect(() => {
    if (imageUpload) {
      if (imageUpload.profileAsFile) {
        updateProfileInfo()
      }
    }
  }, [imageUpload])

  useEffect(() => {
    if (profile_id === undefined) {
      if (props.profileDetails === null || props.profileDetails === undefined) {
        get_profile_info().then(result => {
          if (result.success) {
            setProfileDetails(result.profile)
            setVisible(true)
          }
        })
      }
    }
    else {
      get_profile_info_profile_id(profile_id).then(result => {
        if (result.success) {
          setProfileDetails(result.profile)
          setVisible(true)
        }
      })
    }
  }, [])


  const HandleChangeProfileInfo = (e) => {
    const { name, value } = e.target;

    setNewProfileInfo({
      ...newProfileInfo,
      [name]: value,
    });
  };

  const updateProfileInfo = () => {
    const formData = new FormData();
    if ((newProfileInfo && newProfileInfo.name !== profileDetails.name) || imageUpload) {
      if (newProfileInfo.name) {
        formData.append("name", newProfileInfo.name);
      }
      if (imageUpload) {
        formData.append("user_image", imageUpload.profileAsFile);
      }

      setLoadingModal(true)
      setLoadingMessage('Saving your details!')
      update_profile_details(formData).then(result => {
        if (result.success) {
          setEditProfileName(false)
          if (newProfileInfo.name) {
            setProfileDetails(profileDetails => ({ ...profileDetails, name: newProfileInfo.name }))
          }
          if (imageUpload) {
            setProfileDetails(profileDetails => ({ ...profileDetails, user_image: imageUpload.profilePreview }))
          }
          setLoadingMessage('')
          setLoadingModal(false)
          setImageUpload(null)
        }
      })
    }
    else {
      setEditProfileName(false)
      setEditProfileImage(false)

    }
  }

  return (
    <>
      {!profileDetails ?
        <ThreeDotsLoader size={"30"} center={true}/>
        :
        <div className="w3-row" id="profile-scroll">
          {(visible || props.visible) &&
            <div className='profile-section' style={{ backgroundColor: colors.light_purple_1, color: colors.dark_blue_2, minHeight: '100vh' }}>
              <div className="w3-row w3-padding">
                <div className="w3-third w3-left-align w3-padding-small">
                  <div className='w3-row w3-padding-small w3-card w3-round-large stat-light-card'>
                    {editProfileName ?
                      <>
                        <div style={{ display: 'inline-flex' }}>

                          <h3>
                            <input type="text" defaultValue={profileDetails.name} name="name" onChange={(e) => HandleChangeProfileInfo(e)} className="gret-input-line" />
                            {loadingModal ?
                              <div>
                                <ThreeDotsLoader />
                              </div>
                              :
                              <i className="fa fa-check" style={{ cursor: 'pointer' }} onClick={updateProfileInfo}></i>
                            }
                          </h3>
                        </div>

                        <h4>I am 3D creator</h4>
                        <h4>Contact me for projects: {profileDetails ? profileDetails.email : "Loading email"}</h4>
                      </>
                      :
                      <div className=''>
                        <h3 style={{ cursor: 'pointer' }} onClick={() => setEditProfileName(true)}>
                          {profileDetails ? profileDetails.name : "Loading Name"}
                          {!profile_id &&
                            <TbEdit style={{ color: colors.dark_blue_2, marginLeft: 4 }} />
                          }
                        </h3>
                        <h4>I am 3D creator</h4>
                        {!profile_id &&
                          <h4>XP Points: {profileDetails.profile_points}</h4>
                        }
                        <h4>Contact me for projects:</h4>
                        <h4>{profileDetails ? profileDetails.email : "Loading email"}</h4>
                      </div>
                    }
                  </div>
                </div>
                <div className="w3-twothird">
                  <ProfileStats statsData={profileDetails?.profile_stats} />
                </div>
              </div>
              <div className='w3-margin-top w3-row w3-padding w3-half'>
                <PointsTable />
              </div>
              <div className='w3-margin-top w3-row w3-padding w3-half'>
                <PointsHistoryTable />
              </div>
            </div>
          }
        </div>
      }
    </>
  )
}

export default React.memo(ChallengeProfile)

