import { useState, useCallback, useEffect } from 'react';
import { getCookieKey } from '../../../LocalStorage/CookiesStorage'; 
import environment from '../../../Environments/Environment';

const useExperience = (experience_id, is_editing = false) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchExperience = useCallback(async () => {
        setLoading(true);
        try {
            const token = 'Token ' + getCookieKey('token');
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Authorization: token },
            };
            const is_superuser = getCookieKey('is_superuser') ? getCookieKey('is_superuser') : false;
            const response = await fetch(
                `${environment.server_root}/api/avatar/get_experience?experience_id=${experience_id}&is_superuser=${is_superuser}&is_editing=${is_editing}`,
                requestOptions
            );
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            result.status_code = response.status;
            setData(result?.experience);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, [experience_id, is_editing]);

    // Initial fetch
    useEffect(() => {
        fetchExperience();
    }, [fetchExperience]);

    return { data, loading, error, refetch: fetchExperience };
};

export default useExperience;