import { useState } from 'react';
import { getCookieKey } from '../../../LocalStorage/CookiesStorage';
import environment from '../../../Environments/Environment'

const useCopyVRWebsite = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [statusCode, setStatusCode] = useState(null);

    const copyVRWebsite = async (unique_id) => {
        if (unique_id) {
            setLoading(true);
            try {
                const token = 'Token ' + getCookieKey('token');
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', Authorization: token },
                    body: JSON.stringify({ "unique_id": unique_id })
                };
                const response = await fetch(`${environment.server_root}/api/avatar/copy_vrwebsite/`, requestOptions);
                const result = await response.json();
                setStatusCode(response.status);

                if (!response.ok) {
                    throw new Error(result.message || 'Error updating experience');
                }

                setData(result);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        }
    };

    return { data, loading, error, statusCode, copyVRWebsite };
};

export default useCopyVRWebsite;