import { useState } from 'react';
import axios from 'axios';
import { getCookieKey } from '../../../LocalStorage/CookiesStorage';
import environment from '../../../Environments/Environment'

const useUpdateExperiencePreview = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [statusCode, setStatusCode] = useState(null);

    const updateExperiencePreview = async (formData) => {
        setLoading(true);
        try {
            const token = 'Token ' + getCookieKey('token');
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: token
                },
            };

            const url = `${environment.server_root}/api/avatar/upload_preview_experience/`;
            const response = await axios.post(url, formData, config);
            const result = response.data;
            setStatusCode(response.status);

            if (response.status !== 200) {
                throw new Error(result.message || 'Error updating experience preview');
            }

            setData(result);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    return { data, loading, error, statusCode, updateExperiencePreview };
};

export default useUpdateExperiencePreview;
