import { useState, useCallback, useEffect } from 'react';
import { getCookieKey } from '../../../LocalStorage/CookiesStorage';
import environment from '../../../Environments/Environment';

const useWebsitesTemplates = (initialIndustry, featured) => {
    const [data, setData] = useState([]); // Initialize data as an array
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [pagination, setPagination] = useState({ // State for pagination
        next_page: null,
        current_page: 1,
        total_pages: null,
    });
    const [industry, setIndustry] = useState(initialIndustry); // Manage dynamic industry changes

    const fetchTemplates = useCallback(async (pageNum, currentIndustry) => {
        setLoading(true);
        const token = 'Token ' + getCookieKey('token');
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Authorization: token },
            params: {}
        };
        let url = '';

        if (featured && currentIndustry) {
            url = `${environment.server_root}/api/avatar/get_vrweb_templates_by_industry?page=${pageNum}&industry=${currentIndustry}&featured=true`;
        } else if (featured && !currentIndustry) {
            url = `${environment.server_root}/api/avatar/get_vrweb_templates_by_industry?page=${pageNum}`;
        } else {
            url = `${environment.server_root}/api/avatar/get_vrweb_templates_by_industry?page=${pageNum}&industry=${currentIndustry}`;
        }

        try {
            const response = await fetch(url, requestOptions);
            const result = await response.json();
            result.status_code = response.status;

            if (response.ok) {
                // Append new data and update pagination
                setData((prevData) => [...prevData, ...result.templates]);
                const newPageData = {
                    next_page: result.next_page, // Assuming the API returns this
                    current_page: pageNum,       // Current page being fetched
                    total_pages: result.total_pages // Total pages available
                };
                setPagination(newPageData);
            } else {
                setError(result); // Handle error response
            }
        } catch (err) {
            setError(err); // Handle fetch error
        } finally {
            setLoading(false);
        }
    }, [featured]);

    // Fetch templates when the industry or featured state changes
    useEffect(() => {
        // Reset the data and pagination state when industry changes
        setData([]);
        setPagination({ next_page: null, current_page: 1, total_pages: null });
        fetchTemplates(1, industry); // Fetch templates for the first page
    }, [industry, featured, fetchTemplates]);

    const loadMore = () => {
        if (pagination.next_page) { // Check if there is a next page
            fetchTemplates(pagination.next_page, industry); // Fetch the next page
        }
    };

    return { data, loading, error, loadMore, pagination, setIndustry };
};

export default useWebsitesTemplates;