import React, { useEffect, useRef, useState, lazy } from 'react'
import './VrVideo.css'
import * as THREE from 'three';
import { VRButton } from 'three/addons/webxr/VRButton.js';
import { get_user_videos } from '../../Servicies/ModelService';
import { IoIosPlay, IoIosPause } from 'react-icons/io';
import { HiVolumeUp, HiVolumeOff } from 'react-icons/hi';
import { get_experience, get_video_experience } from '../../Servicies/ExperienceService';
import { clearThree } from '../../SceneComponents/SceneComponents';
import { getCookieKey, deleteAllCookies, setCookieKey } from '../../LocalStorage/CookiesStorage';
// import { FaUserCircle } from 'react-icons/fa';
import { RiAdminFill } from 'react-icons/ri';
import { set } from 'react-ga';
import { MdOutlinePowerSettingsNew } from 'react-icons/md';
import { get_profile_info } from '../../Servicies/ProfileService';
import 'video-react/dist/video-react.css';
import { Player } from 'video-react';
import { getRemainingTime } from './CommonFunctions';
import * as Colors from '../../Styles/Colors';
import { useNavigate, useParams } from 'react-router-dom';
import SeoTags from '../../Screens/Seo/SeoTags';
import { VRVideoPublishPageTags } from '../../Screens/Seo/SeoPageTags';
import importRetry from '../../importRetry';
import { useUpdateProfileVisits } from '../../Servicies/CustomHook';
// import UploadNewComponent from './UploadNewComponent';
// import LoginButtons from '../../Screens/Signup&LoginPage/LoginButtons';
// import VideoStream from './VideoStream';
// import LoginModal from '../../Screens/PopupComponents/LoginModal';

const UploadNewComponent = lazy(() => importRetry(() => import('./UploadNewComponent')));
const LoginButtons = lazy(() => importRetry(() => import('../../Screens/Signup&LoginPage/LoginButtons')));
const VideoStream = lazy(() => importRetry(() => import('./VideoStream')));
const LoginModal = lazy(() => importRetry(() => import('../../Screens/PopupComponents/LoginModal')));


let camera, scene, renderer;
var video_index = 0, models_next_page = { Uploads: { next_page: 2, current_page: 1, total_pages: 1 } }, my_uploads = []

const VrVideoPlay = () => {

    const { updateProfileVisits } = useUpdateProfileVisits();

    const [isLoading, setIsLoading] = useState(true)
    const [uploadNew, setUploadNew] = useState(false)
    const [loginPopup, setLoginPopup] = useState(false)
    const [logedin, setLogedin] = useState(getCookieKey('token') ? true : false)
    const [admin, setAdmin] = useState(false)
    const [play, setPlay] = useState(true)
    const [mute, setMute] = useState(true)
    const [videoURL, setVideoURL] = useState('')
    const [scene, setScene] = useState(null)
    const [experienceInfo, setExperienceInfo] = useState({})
    const [currentVideo, setCurrentVideo] = useState(0)
    const [myUploadCollection, setMyUploadCollection] = useState([])
    const [refreshLoading, setRefreshLoading] = useState(false)
    const [modelsNextPage, setModelsNextPage] = useState({ Uploads: { next_page: 2, current_page: 1, total_pages: 1 } })
    // const experience_id = 'VRV-My_Vr_video-YWL9'// Orginal Experience Id
    const { experience_id } = useParams()
    // const experience_id = 'VRE-vr-video-3E9686'
    // const admin_email = 'basit316316@gmail.com'
    const admin_email = 'kaushalg1110@gmail.com'
    const videoRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        get_video_experience(experience_id).then(result => {
            if (result.success) {
                setExperienceInfo(result.experience)
                setVideoURL(result.experience.video)
                init()
            }
        })
    }, [])

    useEffect(() => {
        if (experienceInfo) {
            if (scene) {
                setVideoURL(experienceInfo.video)
            }
        }
    }, [experienceInfo])

    useEffect(() => {
        setLogedin(getCookieKey('token') ? true : false)
        if (getCookieKey('token')) {
            setLoginPopup(false)
            setAdmin(false)
            get_profile_info().then(result => {
                if (result.success) {
                    if (result.profile.email === admin_email) {
                        setAdmin(true)
                        fetchUploads()
                    }
                    else {
                        setAdmin(false)
                    }
                    setCookieKey('email', result.profile.email)
                }
            })

        }

    }, [getCookieKey('token')])

    useEffect(() => {
        if (experienceInfo && videoURL && scene) {
            videoToMesh()
        }
    }, [videoURL])

    useEffect(() => {
        if (scene && camera) {
            animate()
        }
    }, [scene, camera])

    useEffect(() => {
        if (renderer) {
            // const controller = renderer.xr.getController(0);
            const controllerRight = renderer.xr.getHand(0);
            controllerRight.addEventListener('selectstart', (event) => {
                updateVideo(true)
            });
            const controllerLeft = renderer.xr.getHand(1);
            controllerLeft.addEventListener('selectstart', (event) => {
                updateVideo(false)
            });
        }
    }, [renderer])

    function getMyuploads(refresh = false, page = 0) {
        setRefreshLoading(refresh)
        var start_index = myUploadCollection.length
        get_user_videos(page).then(result => {
            if (result.success) {
                setRefreshLoading(false)
                for (var i = 0; i < result.videos.length; i++) {
                    result.videos[i]['lock'] = getRemainingTime(result.videos[i].created_at).lock
                }
                refresh ? setMyUploadCollection(result.videos) : setMyUploadCollection([...myUploadCollection, ...result.videos])
                refresh ? my_uploads = result.videos : my_uploads.push(...result.videos)
                var new_page = { "next_page": result.next_page, "current_page": page, "total_pages": result.total_pages }
                models_next_page.Uploads = new_page
                setModelsNextPage(modelsNextPage => ({
                    ...modelsNextPage,
                    ["Uploads"]: new_page
                }));
                for (var i = 0; i < result.videos.length; i++) {
                    if (!getRemainingTime(result.videos[i].created_at).lock) {
                        video_index = start_index + i
                        break;
                    }
                }
            }
        })
    }

    function updateVideo(move_next) {
        if (my_uploads.length > 0 && video_index) {
            if (video_index < my_uploads.length) {
                var obj = my_uploads[video_index]
                setExperienceInfo(prevPersonInfo => ({ ...prevPersonInfo, video_id: obj.id, video: obj.glb_file }))
                if (move_next) {
                    video_index = video_index + 1
                }
                else {
                    if (video_index > 0 && !my_uploads[video_index - 1].lock) {
                        video_index = video_index - 1
                    }
                }
            }
            else {
                if (models_next_page.Uploads.current_page !== models_next_page.Uploads.total_pages) {
                    getMyuploads(false, models_next_page.Uploads.next_page)
                }
                else {
                    // setCurrentVideo()
                }
            }
        }
    }

    function videoToMesh() {
        clearThree(scene)
        var video = document.querySelector("video");
        video.load()
        video.play();
        const texture = new THREE.VideoTexture(video);
        texture.colorSpace = THREE.SRGBColorSpace;
        const geometry1 = new THREE.SphereGeometry(500, 60, 40, Math.PI / 2, Math.PI, 0, Math.PI);
        // invert the geometry on the x-axis so that all of the faces point inward
        geometry1.scale(- 1, 1, 1);

        const uvs1 = geometry1.attributes.uv.array;

        for (let i = 0; i < uvs1.length; i += 2) {

            uvs1[i] *= 0.5;

        }

        const material1 = new THREE.MeshBasicMaterial({ map: texture });

        const mesh1 = new THREE.Mesh(geometry1, material1);
        mesh1.rotation.y = - Math.PI / 2;
        mesh1.layers.set(1); // display in left eye only
        scene.add(mesh1);

        // right

        const geometry2 = new THREE.SphereGeometry(50, 60, 40, Math.PI / 2, Math.PI, 0, Math.PI);
        geometry2.scale(- 1, 1, 1);

        const uvs2 = geometry2.attributes.uv.array;

        for (let i = 0; i < uvs2.length; i += 2) {

            uvs2[i] *= 0.5;
            uvs2[i] += 0.5;

        }

        const material2 = new THREE.MeshBasicMaterial({ map: texture });

        const mesh2 = new THREE.Mesh(geometry2, material2);
        mesh2.rotation.y = - Math.PI / 2;
        mesh2.layers.set(2); // display in right eye only
        scene.add(mesh2);
    }

    function init() {
        const container = document.getElementById('vr-video-container');
        container.addEventListener('click', function () {

            // video.play();

        });

        camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 1, 2000);
        camera.layers.enable(1); // render left view when no stereo available

        // video
        var video = document.querySelector("video");

        video.load()
        video.play();


        var scene = new THREE.Scene();
        setScene(scene)
        scene.background = new THREE.Color(0x101010);


        const texture = new THREE.VideoTexture(video);
        texture.colorSpace = THREE.SRGBColorSpace;
        const geometry1 = new THREE.SphereGeometry(500, 60, 40, Math.PI / 2, Math.PI, 0, Math.PI);
        // invert the geometry on the x-axis so that all of the faces point inward
        geometry1.scale(- 1, 1, 1);

        const uvs1 = geometry1.attributes.uv.array;

        for (let i = 0; i < uvs1.length; i += 2) {

            uvs1[i] *= 0.5;

        }

        const material1 = new THREE.MeshBasicMaterial({ map: texture });

        const mesh1 = new THREE.Mesh(geometry1, material1);
        mesh1.rotation.y = - Math.PI / 2;
        mesh1.layers.set(1); // display in left eye only
        scene.add(mesh1);

        // right

        const geometry2 = new THREE.SphereGeometry(50, 60, 40, Math.PI / 2, Math.PI, 0, Math.PI);
        geometry2.scale(- 1, 1, 1);

        const uvs2 = geometry2.attributes.uv.array;

        for (let i = 0; i < uvs2.length; i += 2) {

            uvs2[i] *= 0.5;
            uvs2[i] += 0.5;

        }

        const material2 = new THREE.MeshBasicMaterial({ map: texture });

        const mesh2 = new THREE.Mesh(geometry2, material2);
        mesh2.rotation.y = - Math.PI / 2;
        mesh2.layers.set(2); // display in right eye only
        scene.add(mesh2);

        renderer = new THREE.WebGLRenderer();
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.xr.enabled = true;
        renderer.xr.setReferenceSpaceType('local');
        container.appendChild(renderer.domElement);

        document.body.appendChild(VRButton.createButton(renderer));

        //

        window.addEventListener('resize', onWindowResize);
        const handleCanPlayThrough = () => {
            video.play();
        };
        video.addEventListener('canplaythrough', handleCanPlayThrough);

        return () => {
            video.removeEventListener('canplaythrough', handleCanPlayThrough);
        };

    }
    function onWindowResize() {

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);

    }

    function animate() {
        renderer.setAnimationLoop(render);
    }

    function render() {
        renderer.render(scene, camera);
    }

    function fetchUploads() {
        if (myUploadCollection.length === 0) {
            getMyuploads(false, 1)
        }
    }

    function openModal() {
        setUploadNew(true)
        fetchUploads()
    }
    function playPause() {
        var video = document.querySelector("video");
        if (video.paused) {
            video.play();
            setPlay(true)
        }
        else {
            video.pause();
            setPlay(false)
        }
    }

    function muteUnmute() {
        var video = document.querySelector("video");
        if (video.muted) {
            video.muted = false;
            setMute(false)
        }
        else {
            video.muted = true;
            setMute(true)
        }
    }

    function Logout() {
        updateProfileVisits({ "end_session": true }).then(result => {
            setLogedin(false)
            setMyUploadCollection([])
            my_uploads = []
            navigate('/vr-video/kevin46')
            deleteAllCookies()
        })
    }

    return (
        <>
            <div className='w3-row w3-display-container'>
                <SeoTags
                    title={`${VRVideoPublishPageTags.title} ${experience_id ? experience_id : ''}`}
                    preview={VRVideoPublishPageTags.preview}
                    href={`${VRVideoPublishPageTags.href}${experience_id ? experience_id : ''}`}
                    description={experience_id ? experienceInfo && experienceInfo?.description : VRVideoPublishPageTags.description}
                />
                <div className='' style={{ position: 'absolute', top: 5, right: 5, display: 'flex' }}>
                    {logedin ?
                        <>
                            <div className='w3-col l4 s4 vr-button-padding'>
                                <button title='Play/Pause Video' className="nav-buttons icon-padding" onClick={playPause} >
                                    <div className="nav-icons-gret" >
                                        {play ? <IoIosPause /> : <IoIosPlay />}
                                    </div>
                                </button>
                            </div>
                            <div className='w3-col l4 s4 vr-button-padding'>
                                <button title='Mute/Unmute Speaker' className="nav-buttons icon-padding" onClick={muteUnmute} >
                                    <div className="nav-icons-gret" >
                                        {mute ? <HiVolumeOff /> : <HiVolumeUp />}
                                    </div>
                                </button>
                            </div>
                            {admin &&
                                <div className='w3-col l4 s4 vr-button-padding'>
                                    <button title='Admin Settings' className="nav-buttons icon-padding" onClick={() => openModal()} >
                                        <div className="nav-icons-gret" >
                                            <RiAdminFill />
                                        </div>
                                    </button>
                                </div>
                            }
                            <div className='w3-col l4 s4 vr-button-padding'>
                                <button title='Logout' className="nav-buttons icon-padding" onClick={Logout} >
                                    <div className="nav-icons-gret" >
                                        <MdOutlinePowerSettingsNew />
                                    </div>
                                </button>
                            </div>
                        </>
                        :
                        // <div className='w3-col l12 s12 vr-button-padding'>
                        //     <button title='Login' className="nav-buttons icon-padding" onClick={() => setLoginPopup(true)} >
                        //         <div className="nav-icons-gret" >
                        //             <FaUserCircle />
                        //         </div>
                        //     </button>
                        // </div>
                        <></>
                    }

                </div>
            </div>
            <div id="vr-video-container" style={{ display: logedin ? 'block' : 'none' }}></div>
            {logedin ?
                <></>
                :
                <>
                    <div className='center-item ' style={{ backgroundColor: Colors.grey, height: '100vh', flexDirection: 'column', color: 'white' }}>
                        <div className='w3-col l3 s12'>

                            <LoginButtons color='white' />
                        </div>
                        {/* <h1 className="w3-padding w3-center no-margin"><b>Welcome!</b></h1>
                        <h4 className="w3-padding right_section1 w3-center">
                            Login to continue
                        </h4>
                        <div className='w3-padding w3-col l1 s4'>
                            <button className='button-light' onClick={() => setLoginPopup(true)}>Login</button>
                        </div> */}
                    </div>
                </>}
            {/* <video id="video" ref={videoRef} loop muted autoPlay crossOrigin="anonymous" preload="metadata" controls playsInline style={{ display: 'none' }} /> */}
            <div style={{ display: 'none' }}>
                {/* <VideoStream src={videoURL}/> */}
                <Player autoPlay={true} muted={true} loop={true} crossOrigin="anonymous" src={videoURL}>
                </Player>
            </div>
            <div className='w3-card w3-display-bottomleft w3-light-grey w3-padding w3-round-medium'>
                Can't View in VR, add
                <a href='https://chromewebstore.google.com/detail/webxr-api-emulator/mjddjgeghkdijejnciaefnkjmkafnnje' target='_blank' style={{ textDecoration: 'none !important' }}> WebXR API Emulator </a>
                extension to your Chrome Browser
            </div>

            {/* <source src={videoURL} type="video/mp4" />
                Sorry, your browser doesn't support embedded videos.
                {/* <source src="https://storage.googleapis.com/grubox-store/gret/avatar/28/28.webm" /> */}
            {/* </video> */}
            {uploadNew && <UploadNewComponent
                visible={uploadNew}
                closeHandle={() => setUploadNew(false)}
                getMyuploads={getMyuploads}
                myUploadCollection={myUploadCollection}
                setMyUploadCollection={setMyUploadCollection}
                modelsNextPage={modelsNextPage}
                setModelsNextPage={setModelsNextPage}
                refreshLoading={refreshLoading}
                setRefreshLoading={setRefreshLoading}
                experienceInfo={experienceInfo}
                setExperienceInfo={setExperienceInfo}
            />
            }
            {loginPopup &&
                <LoginModal visible={loginPopup} closeHandle={() => setLoginPopup(false)} />
            }
        </>

    )
}

export default VrVideoPlay
