export function getRemainingTime(targetDateTime) {
    var currentDate = new Date();  // Current date and time
    var targetDate = new Date(targetDateTime);  // Target date
    targetDate.setDate(targetDate.getDate() + 1)
    // Calculate the time difference in milliseconds
    var timeDifference = targetDate.getTime() - currentDate.getTime();

    // Calculate the time difference in hours and minutes
    var hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    var minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    // console.log("Time difference in hours:", hoursDifference)
    // Display the time difference
    var status={
        lock:false,
        text:''
    }
    if (hoursDifference < 0) {
        return status
    }
    else {
        status.lock=true
        if (hoursDifference > 0) {
            // console.log("Time difference:", hoursDifference, "hours", minutesDifference, "minutes");
            status.text= "Video will be available in "+hoursDifference + " hours " + minutesDifference + " minutes"
            return status
        } else {
            // console.log("Time difference:", minutesDifference, "minutes");
            status.text= "Video will be available in "+minutesDifference + " minutes"
            return status
        }
    }
}