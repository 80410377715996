export  default async function importRetry(importFn, retries = 3, interval = 1000) {
    try {
        return await importFn();
    } catch (error) {
        if (retries) {
            await wait(interval);
            return importRetry(importFn, retries - 1, interval);
        } else {
            throw new Error(error);
        }
    }
}
function wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}